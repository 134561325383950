/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/@progress/kendo-theme-material/dist/all");
@import 'app/shared/styles/colors.scss';
@import 'app/shared/styles/browser-compatibility.scss';
@import 'app/shared/styles/fonts.scss';

html, body {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

body .container {
  margin-left: none;
  margin-right: none;
  max-width: 100%;
  padding: 0;
}

.m5 {
  margin-right: 5px;
}

footer {
  height: 100px;
}

.footer-divider {
  background-color: $engieblue;
  height: 2px;
  margin: 30px 28px 30px 0px;
}

.container-base {
  padding-right: 30px;
}

@media(max-width: 800px) {
  .container-base {
    padding: 16px;
  }
}

.dropdown>.entity-inner-section>kendo-dropdownlist.k-dropdown {
  color: $white;
  border-bottom: 1px solid $white;

  .k-input {
    color: $white !important;
  }
}

kendo-datepicker>.k-picker-wrap>kendo-dateinput {
  font-size: 13px;
}

.k-animation-container {
  border-radius: 5px;
}

.engie-light-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
}

.chemix-light-logo {
  width: 170px;
  margin-bottom: 40px;
  margin-top: -75px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: $white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

app-root {
  width: 100%;
  height: 100%;
  display: block;
}

.loader {
  background: $engieblue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blur-content {
  filter: blur(1.5px);
}

.k-dropdown-wrap>.k-input {
  // fix for dropdownlists in IE
  flex-basis: auto;
}

.k-notification-group,
.k-notification-container {
  width: 100%;
}

.k-notification-group {
  margin-top: 40px;
  padding-left: 110px;
  padding-right: 15px;
}

@media(max-width: 800px) {
  .k-notification-group {
    top: 10px !important;
    margin-top: 40px;
    padding-left: 0px;
    padding-right: 0px;

    .k-notification {
      font-size: 13px;
      height: fit-content;
    }
  }
}

.k-notification {
  padding: 10px 20px;
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.k-notification-wrap>.k-icon {
  margin-top: 6px;
}

.k-notification-content {
  margin-left: 20px;
}

.k-notification-success {
  background-color: $lightgreen;
  color: $success;
}

.k-notification-error {
  background-color: $lightred;
  color: $error;
}

.k-i-success {
  content: url('/assets/icons/done-success-green.svg');
}

.k-i-error {
  content: url('/assets/icons/error-red.svg');
}

kendo-dialog.disclaimer-dialog>div.k-widget.k-window.k-dialog.ng-trigger.ng-trigger-dialogSlideInAppear {
  height: 100vh;
  @extend .k-window-non-adjusted;
}

/** GRIDS **/

//general grid options
.k-grid {
  border-width: 0;

  .k-grid-header-wrap {
    border-width: 0px;
  }

  table {
    tr {
      height: 46px;
      &:hover {
        background-color: transparent;
      }
    }

    td {
      @extend .b2-reg;
    }
  }

  .k-grid-table {
    padding: 3px;

    .k-master-row {
      &.expanded td {
        border-color: transparent;
      }
    }
  }

  .k-hierarchy-cell, .k-hierarchy-col {
    width: 40px !important;
    max-width: 40px;
    word-wrap: break-word;
  }

  .k-hierarchy-cell {
    .k-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%230AF' fill-rule='nonzero' d='M12.644 10.495l-4.298 4.298a.7.7 0 1 1-.99-.99L11.159 10 7.356 6.197a.7.7 0 0 1 .99-.99l4.298 4.298a.698.698 0 0 1 0 .99z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(0);
      transition: 100ms;
      height: 18px;
      display: block;

      &.k-minus {
        transform: rotate(90deg);
      }
    }

    .k-minus::before,
    .k-plus::before {
      content: none;
    }
  }

  th.k-header {
    color: $grey !important;
    padding: 0;
    border-width: 0;

    &>div {
      margin-right: 20px;
      border-bottom: 1px solid $grey;
      padding-bottom: 7px;
      font-weight: normal;
    }
  }

  tr.k-filter-row td {
    padding: 10px 0;
  }

  .k-grid-header {
    padding: 0 !important;
    border-width: 0;
  }

  .k-grid-content {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .k-master-row.expanded {
    background-color: $icegrey;

    &:hover {
      background-color: $icegrey;
    }
  }
}

// tile table and expandable table

.chemix-tile-table {
  .k-grid-table {
    border-collapse: separate;
    border-spacing: 0 10px;

    @include IE10-only {
      border-collapse: collapse;
    }

    tr {
      box-shadow: 0 3px 4px 0 rgba(110, 110, 110, 0.25);
    }
  }
}

.k-grid.data-section-grid {
  td {
    padding: 0 24px;
  }
}

.k-grid.chemix-expandable-table {
  .k-detail-cell, .k-master-row {
    padding: 0px;
  }

  .k-detail-row {
    .k-hierarchy-cell {
      display: none;
    }
  }

  tbody {
    tr {
      box-shadow: 0 2px 4px 0 rgba(110, 110, 110, 0.25);
    }

    td {
      padding-left: 0px;
    }
  }

  @include IE10-only {
    .k-grid-table {
      border-collapse: collapse;

      tr {
        box-shadow: 0 3px 4px 0 rgba(110, 110, 110, 0.25);
      }
    }
  }
}

.k-grid.row-46 {
  tr {
    height: 46px;
  }
}

.k-grid.row-56 {
  tr {
    height: 56px;
  }
}

// grid filters

.k-filtercell {
  margin: 0 20px 0 0;

  .k-filtercell-operator {
    margin-left: 0px;

    button {
      display: none;
    }
  }

  .k-filtercell-wrapper {
    &:hover {
      border-bottom: 1px solid $engieblue;
    }

    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid $coolgrey;

    input {
      border: none;
      font-weight: bold;
      color: $engieblue;
    }

    .k-dropdown-wrap .k-select {
      justify-content: left;
    }
  }
}

.Code.ng-star-inserted {
  color: $engieblue;
}

.k-grid tr.forbidden, .k-grid tr.forbidden:hover {
  background-color: $icegrey;
}

/* FORM */

.form-input {
  color: $engieblue;

  &:focus {
    font-weight: bold;
  }
}

.medium-input {
  .k-combobox, .k-textbox, .k-numerictextbox {
    width: 250px;
  }
}

.small-input {
  .k-combobox,.k-textbox, .k-numerictextbox, .k-datepicker {
    width: 160px;
  }
}

.large-input {
  .k-combobox, .k-textbox, .k-dropdown {
    width: 535px;
  }
}

.medium-input, .large-input, .small-input {
  kendo-combobox>span.k-dropdown-wrap,
  .k-textbox,
  kendo-dropdownlist>span.k-dropdown-wrap {
    border-bottom: 1px $grey solid;
  }

  .k-combobox, .k-textbox, .k-input {
    font-size: 13px;

    kendo-searchbar>input, kendo-numerictextbox>.k-numeric-wrap>input {
      @extend .form-input;
    }
  }

  .k-textbox {
    @extend .form-input;
  }
}

.submit-attempt {
  .small-input, .medium-input, .large-input {
    kendo-combobox.ng-invalid>span.k-dropdown-wrap,
    kendo-numerictextbox.ng-invalid>span.k-numeric-wrap,
    kendo-datepicker.ng-invalid>span.k-picker-wrap,
    input.k-textbox.ng-invalid,
    .k-textarea.ng-invalid{
      color: red;
      border-bottom: 1px red solid;

      ::placeholder {
        color: red;
      }
    }
  }
}

@media(max-width: 400px) {
  .medium-input,.large-input, .small-input {
    .k-textbox, .k-combobox, .k-datepicker, .k-numerictextbox {
      min-width: none;
      max-width: none;
      width: 100% !important;
    }
  }
}

@media(max-width: 600px) {
  // adjusting kendo comboboxes styles on mobile
  .k-widget.k-combobox .k-dropdown-wrap {
    .k-icon.k-clear-value {
      right: 45px; // moving clear icon (x) more to the left
    }

    .k-select .k-icon {
      // emphesizing dropdown arrow
      &.k-i-arrow-s {
        width: 2em;
        height: 15px;
        font-size: 20px;

        &::before {
          float: right;
        }
      }
    }
  }
}

@media(max-width: 700px) {
  .large-input>.k-combobox, .k-textbox {
    width: 100%;
  }
}

.k-textbox {
  font-size: 13px;
  padding-bottom: 2px;
  color: $engieblue;

  &:focus {
    font-weight: bold;
    color: $engieblue;
  }
}

.search-panel {
  .k-textbox-container {
    width: 340px;

    input {
      width: 100%;
    }
  }
}

.ghs-multiselect>.k-multiselect-wrap {
  width: 535px;
}

/* EDIT ARTICLE MODAL */

app-edit-article-modal>.k-window {
  left: 25vw !important;
}

.edit-article-modal, .comparison-section {
  .chemix-dropdown {

    span.k-dropdown-wrap, .k-textbox {
      width: 100%;
    }

    .k-input {
      font-size: 13px;
      color: $engieblue;
    }
  }

  .k-content {
    overflow: hidden;
  }

  .k-state-selected {
    color: $engieblue;
  }

  .k-multiselect-wrap {

    .k-button,
    .k-button:hover {
      color: $engieblue;
      border: 1px solid $engieblue;
      background-color: transparent;
    }

    .k-button .k-select {
      color: $grey;
      background-color: transparent;
    }

    .k-i-loading {
      display: none;
    }

    .k-input {
      font-size: 13px;
      padding-bottom: 2px;
      color: $engieblue;

      &:focus {
        font-weight: bold;
        color: $engieblue;
      }
    }
  }
}

/* MODALS */

.k-dialog-wrapper .k-dialog {
  position: initial;
}

.k-window-non-adjusted {
  left: 0px !important;
}

.k-window {
  left: 30vw !important;
}

.mobile-filters {
  .k-window-titlebar {
    padding: 20px 10px 16px 10px;
    font-weight: bold;
  }

  .k-dialog-actions {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .k-dialog-content {
    padding: 0 16px;
  }

  .k-window {
    @extend .k-window-non-adjusted;
  }
}

.chemix-dialog {
  .k-window-titlebar {
    padding: 0px;
  }

  .k-i-close {
    color: $engieblue;
  }
}

.help>kendo-dialog-titlebar {
  padding: 24px 0px;
}
$engieblue: #00aaff;
$airforceblue: #458ad5;
$navyblue: #0078be;
$darkgrey: #414042;
$grey: #979797;
$coolgrey: #cccccc;
$lightgrey: #eeeeee;
$success: #64dd17;
$lightgreen: #f3fded;
$yellow: rgb(249, 235, 0);
$warning: #ffc300;
$tomato: rgb(255, 144, 0);
$error: #d50000;
$lightred: #fcebeb;
$lightwarning: rgb(255, 251, 235);
$icegrey: #f5f5f5;
$white: #ffffff;
$lightengieblue: rgba(245, 252, 255, 1);
$lightgrey: #eeeeee;
$darkwhite: #000;
$shadow: rgba(110, 110, 110, 0.25);

/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/@progress/kendo-theme-material/dist/all");
/** Here should be definitions (mixins) for targeting particular browsers if needed for styles that are not fully cross-browser compatible */
/** Fonts classes as defined in Zeplin Text style catalogue styleguide */
.b2-reg, .k-grid table td {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: normal;
  color: #414042;
}

.chemix-font-bold {
  color: #414042;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.requirement {
  color: #cccccc;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  line-height: 16px;
  min-width: 42px;
  width: auto;
  margin-left: 4px;
}

.requirement.error {
  color: #d50000;
  font-weight: bold;
}

.description {
  color: #414042;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

html, body {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

body .container {
  margin-left: none;
  margin-right: none;
  max-width: 100%;
  padding: 0;
}

.m5 {
  margin-right: 5px;
}

footer {
  height: 100px;
}

.footer-divider {
  background-color: #00aaff;
  height: 2px;
  margin: 30px 28px 30px 0px;
}

.container-base {
  padding-right: 30px;
}

@media (max-width: 800px) {
  .container-base {
    padding: 16px;
  }
}
.dropdown > .entity-inner-section > kendo-dropdownlist.k-dropdown {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.dropdown > .entity-inner-section > kendo-dropdownlist.k-dropdown .k-input {
  color: #ffffff !important;
}

kendo-datepicker > .k-picker-wrap > kendo-dateinput {
  font-size: 13px;
}

.k-animation-container {
  border-radius: 5px;
}

.engie-light-logo {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
}

.chemix-light-logo {
  width: 170px;
  margin-bottom: 40px;
  margin-top: -75px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ffffff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
app-root {
  width: 100%;
  height: 100%;
  display: block;
}

.loader {
  background: #00aaff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blur-content {
  filter: blur(1.5px);
}

.k-dropdown-wrap > .k-input {
  flex-basis: auto;
}

.k-notification-group,
.k-notification-container {
  width: 100%;
}

.k-notification-group {
  margin-top: 40px;
  padding-left: 110px;
  padding-right: 15px;
}

@media (max-width: 800px) {
  .k-notification-group {
    top: 10px !important;
    margin-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .k-notification-group .k-notification {
    font-size: 13px;
    height: fit-content;
  }
}
.k-notification {
  padding: 10px 20px;
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.k-notification-wrap > .k-icon {
  margin-top: 6px;
}

.k-notification-content {
  margin-left: 20px;
}

.k-notification-success {
  background-color: #f3fded;
  color: #64dd17;
}

.k-notification-error {
  background-color: #fcebeb;
  color: #d50000;
}

.k-i-success {
  content: url("/assets/icons/done-success-green.svg");
}

.k-i-error {
  content: url("/assets/icons/error-red.svg");
}

kendo-dialog.disclaimer-dialog > div.k-widget.k-window.k-dialog.ng-trigger.ng-trigger-dialogSlideInAppear {
  height: 100vh;
}

/** GRIDS **/
.k-grid {
  border-width: 0;
}
.k-grid .k-grid-header-wrap {
  border-width: 0px;
}
.k-grid table tr {
  height: 46px;
}
.k-grid table tr:hover {
  background-color: transparent;
}
.k-grid .k-grid-table {
  padding: 3px;
}
.k-grid .k-grid-table .k-master-row.expanded td {
  border-color: transparent;
}
.k-grid .k-hierarchy-cell, .k-grid .k-hierarchy-col {
  width: 40px !important;
  max-width: 40px;
  word-wrap: break-word;
}
.k-grid .k-hierarchy-cell .k-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%230AF' fill-rule='nonzero' d='M12.644 10.495l-4.298 4.298a.7.7 0 1 1-.99-.99L11.159 10 7.356 6.197a.7.7 0 0 1 .99-.99l4.298 4.298a.698.698 0 0 1 0 .99z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0);
  transition: 100ms;
  height: 18px;
  display: block;
}
.k-grid .k-hierarchy-cell .k-icon.k-minus {
  transform: rotate(90deg);
}
.k-grid .k-hierarchy-cell .k-minus::before,
.k-grid .k-hierarchy-cell .k-plus::before {
  content: none;
}
.k-grid th.k-header {
  color: #979797 !important;
  padding: 0;
  border-width: 0;
}
.k-grid th.k-header > div {
  margin-right: 20px;
  border-bottom: 1px solid #979797;
  padding-bottom: 7px;
  font-weight: normal;
}
.k-grid tr.k-filter-row td {
  padding: 10px 0;
}
.k-grid .k-grid-header {
  padding: 0 !important;
  border-width: 0;
}
.k-grid .k-grid-content {
  overflow-y: auto;
  overflow-x: hidden;
}
.k-grid .k-master-row.expanded {
  background-color: #f5f5f5;
}
.k-grid .k-master-row.expanded:hover {
  background-color: #f5f5f5;
}

.chemix-tile-table .k-grid-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  /* targets IE 10+ specific styles.*/
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .chemix-tile-table .k-grid-table {
    /* IE10+ CSS styles go here */
    border-collapse: collapse;
  }
}
.chemix-tile-table .k-grid-table tr {
  box-shadow: 0 3px 4px 0 rgba(110, 110, 110, 0.25);
}

.k-grid.data-section-grid td {
  padding: 0 24px;
}

.k-grid.chemix-expandable-table {
  /* targets IE 10+ specific styles.*/
}
.k-grid.chemix-expandable-table .k-detail-cell, .k-grid.chemix-expandable-table .k-master-row {
  padding: 0px;
}
.k-grid.chemix-expandable-table .k-detail-row .k-hierarchy-cell {
  display: none;
}
.k-grid.chemix-expandable-table tbody tr {
  box-shadow: 0 2px 4px 0 rgba(110, 110, 110, 0.25);
}
.k-grid.chemix-expandable-table tbody td {
  padding-left: 0px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .k-grid.chemix-expandable-table {
    /* IE10+ CSS styles go here */
  }
  .k-grid.chemix-expandable-table .k-grid-table {
    border-collapse: collapse;
  }
  .k-grid.chemix-expandable-table .k-grid-table tr {
    box-shadow: 0 3px 4px 0 rgba(110, 110, 110, 0.25);
  }
}

.k-grid.row-46 tr {
  height: 46px;
}

.k-grid.row-56 tr {
  height: 56px;
}

.k-filtercell {
  margin: 0 20px 0 0;
}
.k-filtercell .k-filtercell-operator {
  margin-left: 0px;
}
.k-filtercell .k-filtercell-operator button {
  display: none;
}
.k-filtercell .k-filtercell-wrapper {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #cccccc;
}
.k-filtercell .k-filtercell-wrapper:hover {
  border-bottom: 1px solid #00aaff;
}
.k-filtercell .k-filtercell-wrapper input {
  border: none;
  font-weight: bold;
  color: #00aaff;
}
.k-filtercell .k-filtercell-wrapper .k-dropdown-wrap .k-select {
  justify-content: left;
}

.Code.ng-star-inserted {
  color: #00aaff;
}

.k-grid tr.forbidden, .k-grid tr.forbidden:hover {
  background-color: #f5f5f5;
}

/* FORM */
.form-input, .medium-input .k-textbox, .large-input .k-textbox, .small-input .k-textbox, .medium-input .k-combobox kendo-searchbar > input, .medium-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input, .medium-input .k-textbox kendo-searchbar > input, .medium-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input, .medium-input .k-input kendo-searchbar > input, .medium-input .k-input kendo-numerictextbox > .k-numeric-wrap > input, .large-input .k-combobox kendo-searchbar > input, .large-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input, .large-input .k-textbox kendo-searchbar > input, .large-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input, .large-input .k-input kendo-searchbar > input, .large-input .k-input kendo-numerictextbox > .k-numeric-wrap > input, .small-input .k-combobox kendo-searchbar > input, .small-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input, .small-input .k-textbox kendo-searchbar > input, .small-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input, .small-input .k-input kendo-searchbar > input, .small-input .k-input kendo-numerictextbox > .k-numeric-wrap > input {
  color: #00aaff;
}
.form-input:focus, .medium-input .k-textbox:focus, .large-input .k-textbox:focus, .small-input .k-textbox:focus, .medium-input .k-combobox kendo-searchbar > input:focus, .medium-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input:focus, .medium-input .k-textbox kendo-searchbar > input:focus, .medium-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input:focus, .medium-input .k-input kendo-searchbar > input:focus, .medium-input .k-input kendo-numerictextbox > .k-numeric-wrap > input:focus, .large-input .k-combobox kendo-searchbar > input:focus, .large-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input:focus, .large-input .k-textbox kendo-searchbar > input:focus, .large-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input:focus, .large-input .k-input kendo-searchbar > input:focus, .large-input .k-input kendo-numerictextbox > .k-numeric-wrap > input:focus, .small-input .k-combobox kendo-searchbar > input:focus, .small-input .k-combobox kendo-numerictextbox > .k-numeric-wrap > input:focus, .small-input .k-textbox kendo-searchbar > input:focus, .small-input .k-textbox kendo-numerictextbox > .k-numeric-wrap > input:focus, .small-input .k-input kendo-searchbar > input:focus, .small-input .k-input kendo-numerictextbox > .k-numeric-wrap > input:focus {
  font-weight: bold;
}

.medium-input .k-combobox, .medium-input .k-textbox, .medium-input .k-numerictextbox {
  width: 250px;
}

.small-input .k-combobox, .small-input .k-textbox, .small-input .k-numerictextbox, .small-input .k-datepicker {
  width: 160px;
}

.large-input .k-combobox, .large-input .k-textbox, .large-input .k-dropdown {
  width: 535px;
}

.medium-input kendo-combobox > span.k-dropdown-wrap,
.medium-input .k-textbox,
.medium-input kendo-dropdownlist > span.k-dropdown-wrap, .large-input kendo-combobox > span.k-dropdown-wrap,
.large-input .k-textbox,
.large-input kendo-dropdownlist > span.k-dropdown-wrap, .small-input kendo-combobox > span.k-dropdown-wrap,
.small-input .k-textbox,
.small-input kendo-dropdownlist > span.k-dropdown-wrap {
  border-bottom: 1px #979797 solid;
}
.medium-input .k-combobox, .medium-input .k-textbox, .medium-input .k-input, .large-input .k-combobox, .large-input .k-textbox, .large-input .k-input, .small-input .k-combobox, .small-input .k-textbox, .small-input .k-input {
  font-size: 13px;
}
.submit-attempt .small-input kendo-combobox.ng-invalid > span.k-dropdown-wrap,
.submit-attempt .small-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap,
.submit-attempt .small-input kendo-datepicker.ng-invalid > span.k-picker-wrap,
.submit-attempt .small-input input.k-textbox.ng-invalid,
.submit-attempt .small-input .k-textarea.ng-invalid, .submit-attempt .medium-input kendo-combobox.ng-invalid > span.k-dropdown-wrap,
.submit-attempt .medium-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap,
.submit-attempt .medium-input kendo-datepicker.ng-invalid > span.k-picker-wrap,
.submit-attempt .medium-input input.k-textbox.ng-invalid,
.submit-attempt .medium-input .k-textarea.ng-invalid, .submit-attempt .large-input kendo-combobox.ng-invalid > span.k-dropdown-wrap,
.submit-attempt .large-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap,
.submit-attempt .large-input kendo-datepicker.ng-invalid > span.k-picker-wrap,
.submit-attempt .large-input input.k-textbox.ng-invalid,
.submit-attempt .large-input .k-textarea.ng-invalid {
  color: red;
  border-bottom: 1px red solid;
}
.submit-attempt .small-input kendo-combobox.ng-invalid > span.k-dropdown-wrap ::placeholder,
.submit-attempt .small-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap ::placeholder,
.submit-attempt .small-input kendo-datepicker.ng-invalid > span.k-picker-wrap ::placeholder,
.submit-attempt .small-input input.k-textbox.ng-invalid ::placeholder,
.submit-attempt .small-input .k-textarea.ng-invalid ::placeholder, .submit-attempt .medium-input kendo-combobox.ng-invalid > span.k-dropdown-wrap ::placeholder,
.submit-attempt .medium-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap ::placeholder,
.submit-attempt .medium-input kendo-datepicker.ng-invalid > span.k-picker-wrap ::placeholder,
.submit-attempt .medium-input input.k-textbox.ng-invalid ::placeholder,
.submit-attempt .medium-input .k-textarea.ng-invalid ::placeholder, .submit-attempt .large-input kendo-combobox.ng-invalid > span.k-dropdown-wrap ::placeholder,
.submit-attempt .large-input kendo-numerictextbox.ng-invalid > span.k-numeric-wrap ::placeholder,
.submit-attempt .large-input kendo-datepicker.ng-invalid > span.k-picker-wrap ::placeholder,
.submit-attempt .large-input input.k-textbox.ng-invalid ::placeholder,
.submit-attempt .large-input .k-textarea.ng-invalid ::placeholder {
  color: red;
}

@media (max-width: 400px) {
  .medium-input .k-textbox, .medium-input .k-combobox, .medium-input .k-datepicker, .medium-input .k-numerictextbox, .large-input .k-textbox, .large-input .k-combobox, .large-input .k-datepicker, .large-input .k-numerictextbox, .small-input .k-textbox, .small-input .k-combobox, .small-input .k-datepicker, .small-input .k-numerictextbox {
    min-width: none;
    max-width: none;
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .k-widget.k-combobox .k-dropdown-wrap .k-icon.k-clear-value {
    right: 45px;
  }
  .k-widget.k-combobox .k-dropdown-wrap .k-select .k-icon.k-i-arrow-s {
    width: 2em;
    height: 15px;
    font-size: 20px;
  }
  .k-widget.k-combobox .k-dropdown-wrap .k-select .k-icon.k-i-arrow-s::before {
    float: right;
  }
}
@media (max-width: 700px) {
  .large-input > .k-combobox, .k-textbox {
    width: 100%;
  }
}
.k-textbox {
  font-size: 13px;
  padding-bottom: 2px;
  color: #00aaff;
}
.k-textbox:focus {
  font-weight: bold;
  color: #00aaff;
}

.search-panel .k-textbox-container {
  width: 340px;
}
.search-panel .k-textbox-container input {
  width: 100%;
}

.ghs-multiselect > .k-multiselect-wrap {
  width: 535px;
}

/* EDIT ARTICLE MODAL */
app-edit-article-modal > .k-window {
  left: 25vw !important;
}

.edit-article-modal .chemix-dropdown span.k-dropdown-wrap, .edit-article-modal .chemix-dropdown .k-textbox, .comparison-section .chemix-dropdown span.k-dropdown-wrap, .comparison-section .chemix-dropdown .k-textbox {
  width: 100%;
}
.edit-article-modal .chemix-dropdown .k-input, .comparison-section .chemix-dropdown .k-input {
  font-size: 13px;
  color: #00aaff;
}
.edit-article-modal .k-content, .comparison-section .k-content {
  overflow: hidden;
}
.edit-article-modal .k-state-selected, .comparison-section .k-state-selected {
  color: #00aaff;
}
.edit-article-modal .k-multiselect-wrap .k-button,
.edit-article-modal .k-multiselect-wrap .k-button:hover, .comparison-section .k-multiselect-wrap .k-button,
.comparison-section .k-multiselect-wrap .k-button:hover {
  color: #00aaff;
  border: 1px solid #00aaff;
  background-color: transparent;
}
.edit-article-modal .k-multiselect-wrap .k-button .k-select, .comparison-section .k-multiselect-wrap .k-button .k-select {
  color: #979797;
  background-color: transparent;
}
.edit-article-modal .k-multiselect-wrap .k-i-loading, .comparison-section .k-multiselect-wrap .k-i-loading {
  display: none;
}
.edit-article-modal .k-multiselect-wrap .k-input, .comparison-section .k-multiselect-wrap .k-input {
  font-size: 13px;
  padding-bottom: 2px;
  color: #00aaff;
}
.edit-article-modal .k-multiselect-wrap .k-input:focus, .comparison-section .k-multiselect-wrap .k-input:focus {
  font-weight: bold;
  color: #00aaff;
}

/* MODALS */
.k-dialog-wrapper .k-dialog {
  position: initial;
}

.k-window-non-adjusted, .mobile-filters .k-window, kendo-dialog.disclaimer-dialog > div.k-widget.k-window.k-dialog.ng-trigger.ng-trigger-dialogSlideInAppear {
  left: 0px !important;
}

.k-window {
  left: 30vw !important;
}

.mobile-filters .k-window-titlebar {
  padding: 20px 10px 16px 10px;
  font-weight: bold;
}
.mobile-filters .k-dialog-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}
.mobile-filters .k-dialog-content {
  padding: 0 16px;
}
.chemix-dialog .k-window-titlebar {
  padding: 0px;
}
.chemix-dialog .k-i-close {
  color: #00aaff;
}

.help > kendo-dialog-titlebar {
  padding: 24px 0px;
}
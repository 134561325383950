@import './colors.scss';

/** Fonts classes as defined in Zeplin Text style catalogue styleguide */

.b2-reg {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: normal;
  color: $darkgrey;
}

.chemix-font-bold {
  color: $darkgrey;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.requirement {
  color: $coolgrey;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  line-height: 16px;
  min-width: 42px;
  width: auto;
  margin-left: 4px;
}

.requirement.error {
  color: $error;
  font-weight: bold;
}

.description {
  color: $darkgrey;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}